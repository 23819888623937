
import { defineComponent } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmListChipRecipient from '@/components/shared/TmListChipRecipient.vue'
import TmBadgeEditable from '@/components/shared/TmBadgeEditable.vue'
import TmChipPerson from '@/components/shared/TmChipPerson.vue'

export default defineComponent({
  name: 'Chips',
  components: {
    TmChipPerson,
    TmBadgeEditable,
    TmListChipRecipient,
    TmChip,
    TmBadge,
  },
  setup() {
    const recipients = [
      {
        type: 'contact',
        firstName: 'John',
        lastName: 'Doe',
        phone: '(756) 830-1636',
      },
      {
        type: 'list',
        name: 'VIP Customers',
        counter: 234,
      },
      {
        type: 'recipient',
        phone: '+372 59 122 132',
        country: {
          id: 'ee',
        },
      },
      {
        type: 'recipient',
        phone: '(453) 432-6445',
        country: {
          id: 'us',
        },
      },
      {
        type: 'contact',
        firstName: 'Jennifer',
        lastName: 'Crowford',
        phone: '+44 75 8430 1636',
      },
      {
        type: 'contact',
        phone: '74454323432',
        color: 'blue',
      },
    ]
    const sources = [
      {
        icon: 'email',
        text: 'Email To SMS',
      },
      {
        icon: 'tmi-apple',
        text: 'iOS app',
      },
      {
        icon: 'public',
        text: 'Web app',
      },
      {
        icon: 'tmi-android',
        text: 'Android app',
      },
      {
        icon: 'chat_bubble',
        text: 'Messenger',
      },
      {
        icon: 'message',
        text: 'SMS chat',
      },
      {
        icon: 'call_split',
        text: 'Distribution list',
      },
      {
        icon: 'tmi-automation',
        text: 'Automation',
      },
      {
        icon: 'tmi-application',
        text: 'API',
      },
      {
        icon: 'laptop_mac',
        text: 'Desktop app',
      },
      {
        icon: 'tmi-sitemap',
        text: 'SMS Survey',
      },
    ]
    const onCloseClick = () => {
      console.log('onCloseClick')
    }
    return {
      onCloseClick,
      sources,
      recipients,
    }
  },
})
